$background-color: #ffffff;
$light-primary-color: #491650;
$dark-primary-color: #2a0f2e;
$accent-color: #00a8e8;
$primary-text-color: #000000;
$secondary-text-color: #ffffff;

.page {
	font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
	&-route {
		font-size: 0.75rem;
		font-weight: 400;
		font-family: 'Roboto Mono', monospace;
		text-transform: uppercase;
		color: $accent-color;
	}
	&-content {
		padding: 1rem;
		margin: 2.75rem;
		background-color: rgba($dark-primary-color, 1);
		border-radius: 1rem;
		overflow: auto;
		display: flex;
		flex-flow: column nowrap;
		color: $secondary-text-color;
		box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
	}
}

@media (max-width: 780px) {
	.page {
		padding-bottom: 2rem;
	}
	.page-content {
		margin: 1rem;
	}
}

@media print {
	.page {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding-top: 2.5rem;
		background-color: white;
	}
	.page-route {
		font-size: 1.5rem;
		position: absolute;
		top: 1rem;
		color: $light-primary-color;
	}
}
