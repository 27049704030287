@import '../../Constants.scss';

nav {
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
	text-align: right;
	font-family: 'Roboto Mono', monospace;
	color: $primary-text-color;
	padding: 1rem;
}
header {
	width: 100%;
	height: 6rem;
	text-align: right;
	font-weight: 800;
	font-size: 1.5rem;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	span {
		font-size: 1rem;
		color: rgba($primary-text-color, 0.8);
		font-weight: 500;
	}
	svg {
		display: none;
	}
}
nav ul {
	display: flex;
	flex-flow: column nowrap;
	margin: auto 0;
	width: 100%;
	gap: 1rem;
	overflow: hidden;

	li {
		list-style: none;
		a {
			font-size: 1rem;
			transition: color 200ms, font-size 200ms;
			text-decoration: none;
			color: rgba($primary-text-color, 0.6);
			&:hover {
				color: $dark-primary-color;
				cursor: pointer;
			}
		}
	}
}

.list-open {
	max-height: 350px;
	margin: auto 1rem;
}

.selected {
	a {
		color: $primary-text-color;
		font-size: 1.2rem;
		&:hover {
			color: $primary-text-color;
			cursor: default;
		}
	}
}

.nav-icon {
	transition: transform 200ms, background-color 200ms;
	border-radius: 100%;
	&-open {
		transform: rotate(180deg);
	}
	&:active {
		background-color: rgba($dark-primary-color, 0.2);
	}
}

@media (max-width: 780px) {
	nav {
		align-items: flex-start;
	}
	header {
		width: 95%;
		height: auto;
		align-items: baseline;
		justify-content: flex-start;
		margin: 0.5rem 1rem;
		flex-flow: row wrap;
		gap: 1rem;

		svg {
			display: inline;
			height: 1rem;
			width: 1rem;
			justify-self: flex-end;
		}
	}
	nav ul {
		transition: max-height 200ms;
		max-height: 0;
		overflow: hidden;
		margin: auto 0;
		width: auto;
		align-self: flex-start;
		text-align: left;
	}

	.list-open {
		padding: 0 1rem;
		max-height: 350px;
		margin: auto 0;
	}
}
