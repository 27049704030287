@import '../../Constants.scss';

.chip-container {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	gap: 0.5rem;
	margin: 1rem 0;
}
.chip-title {
	font-family: 'Roboto Mono', monospace;
	width: 100%;
	font-weight: 900;
}
.chip {
	cursor: pointer;
	border-radius: 1rem;
	padding: 0.5rem 1rem;
	text-align: center;
	color: $primary-text-color;
	font-size: 1rem;
	font-weight: 700;
	font-family: 'Roboto', Helvetica, sans-serif;
	background-color: white;
	transition: background-color 200ms, color 200ms;

	&:hover {
		color: $secondary-text-color;
		background-color: rgba($accent-color, 0.2);
	}
}
