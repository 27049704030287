@import '../../Constants.scss';

h1 {
	font-family: 'Roboto', Helvetica, sans-serif;
	text-transform: capitalize;
	font-weight: 500;
	text-align: left;
	margin: 1rem 0 0.5rem;
}

p {
	font-family: 'Roboto', Helvetica, sans-serif;
	text-align: justify;
	font-weight: 500;
	line-height: 2rem;
	font-size: 1.25em;
	opacity: 0.85;
	margin: 0;
}

.socials {
	margin-top: 1rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	a {
		color: $secondary-text-color;
		font-family: 'Roboto Mono', monospace;
		text-decoration: none;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: 0.5rem;
		transition: transform 200ms, color 200ms;
		font-weight: 700;
		span {
			flex: 1;
		}
		svg {
			height: 2rem;
			width: 2rem;
			fill: $secondary-text-color;
		}

		&:hover {
			color: $accent-color;
			svg {
				fill: $accent-color;
			}
			transform: translateX(5px);
		}
	}
}

@media (max-width: 900px) {
	.socials {
		grid-template-columns: 1fr;
	}
}
