@import '../../Constants.scss';

.education {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	margin: 2rem 0 0;
}

.education-item {
	background-color: $background-color;
	border-radius: 1rem;
	color: $primary-text-color;
	padding: 1rem;
	display: flex;
	flex-flow: column wrap;
	gap: 0.5rem;
	h3 {
		margin: 0;
	}
	p {
		font-size: 1rem;
		margin: 0;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		span {
			font-weight: 500;
			font-family: 'Roboto Mono', monospace;
		}
	}
}

@media (max-width: 900px) {
	.education {
		grid-template-columns: 1fr;
	}
}
