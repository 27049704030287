@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:ital,wght@0,400;1,500&display=swap');
@import './Constants.scss';
body {
	background-color: $background-color;
	font-family: 'Roboto', Helvetica, sans-serif;
	height: 100vh;
}

.wrapper {
	display: flex;
	flex-flow: row nowrap;
	height: 100vh;
	width: 100%;
	overflow: hidden;
}

.wrapper-nav {
	transition: width 100ms;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	left: 0;
	width: 18rem;
}
.wrapper-container {
	width: 100%;
	flex: 1;
	overflow: auto;
}

footer {
	position: fixed;
	bottom: 0.75rem;
	right: 0;
	left: 0;
	text-align: center;
	font-size: 0.75rem;
	color: rgba($primary-text-color, 0.75);
	z-index: -1;
}

@media (max-width: 1000px) {
	.wrapper-nav {
		width: 15rem;
	}
}

@media (max-width: 780px) {
	.wrapper {
		width: 100%;
		height: auto;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
	}
	.wrapper-nav {
		position: static;
		top: auto;
		bottom: auto;
		left: auto;
		width: 95%;
	}
	.wrapper-container {
		flex: none;
		height: auto;
		position: static;
	}
}
