@import '../../Constants.scss';

.projects {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-auto-rows: auto;
	align-items: flex-start;
	gap: 1rem;
	margin: 2rem 0 0;
}

.project-item {
	padding: 1rem;
	border-radius: 1rem;
	background-color: $background-color;
	color: $primary-text-color;
	display: flex;
	flex-flow: column nowrap;
	flex: 0.5;
	gap: 0.5rem;
	position: relative;
	h4 {
		margin: 0;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: flex-start;
		span {
			font-family: 'Roboto Mono', monospace;
			font-size: 0.75rem;
		}
	}
	p {
		margin: 0;
		font-size: 1rem;
		text-align: justify;
	}

	&-actions {
		display: flex;
		flex-flow: row nowrap;
		gap: 0.5rem;
		position: absolute;
		bottom: 1rem;
		right: 1rem;
		background-color: rgba($background-color, 0.5);
		border-radius: 0.5rem;
		&-item {
			border-radius: 0.5rem;
			text-transform: uppercase;
			padding: 0.3rem;
			font-size: 0.75rem;
			font-family: 'Roboto Mono', monospace;
			font-weight: 700;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			gap: 0.3rem;
			color: $primary-text-color;
			text-decoration: none;
			cursor: pointer;
			svg {
				width: 0.75rem;
				height: 0.75rem;
				transition: transform 200ms;
			}
			&-open {
				svg {
					transform: rotate(180deg);
				}
			}
			transition: background-color 200ms;
			&:active {
				background-color: rgba($accent-color, 0.25);
			}
			&:hover {
				background-color: rgba($accent-color, 0.25);
			}
		}
	}

	&-images-container {
		border-radius: 0.5rem;
		overflow: hidden;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		max-height: 0;
		gap: 0.2rem;
		transition: max-height 200ms;
		&-open {
			max-height: 500px;
		}

		img {
			width: 100%;
			overflow: hidden;
			cursor: pointer;
		}
	}
}

@media (max-width: 850px) {
	.project-item-images-container {
		grid-template-columns: 1fr;
	}
	.project-item-images-container-open {
		max-height: 1200px;
	}
}

@media print {
	.project-item-images-container {
		grid-template-columns: repeat(3, 1fr);
	}
}
