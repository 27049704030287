@import '../../Constants.scss';
@import '../Skills/Skills.scss';

.work {
	display: flex;
	flex-flow: column nowrap;
	gap: 1rem;
	margin: 2rem 0 0;
}

.work-item {
	flex: 1;
	background-color: $background-color;
	border-radius: 1rem;
	color: $primary-text-color;
	padding: 1rem;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 0.5rem;
	&-description {
		text-align: justify;
		color: rgba($primary-text-color, 0.7);
	}
	p {
		font-size: 1rem;
		margin: 0;
		display: flex;
		text-align: justify;
		flex-flow: row wrap;
		justify-content: space-between;
		h3 {
			margin: 0;
		}
		span {
			font-weight: 500;
			font-family: 'Roboto Mono', monospace;
		}
	}
	ul {
		margin: 0;
		line-height: 1.5rem;
		padding: 0.5rem 1.5rem;
		text-align: justify;
	}
}

.work-chips {
	display: flex;
	flex-flow: row wrap;
	gap: 0.5rem;

	h4 {
		margin: 0;
		width: 100%;
		font-size: 1rem;
		font-family: 'Roboto Mono', monospace;
	}
}
.work-chip {
	@extend .chip;
	background-color: $dark-primary-color;
	color: $secondary-text-color;
	&:hover {
		background-color: $secondary-text-color;
		color: $dark-primary-color;
	}
}
