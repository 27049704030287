body {
	margin: 0;
	font-family: 'Roboto', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-thumb {
	background: #f7dbf0;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: linear-gradient(13deg, #c7ceff 14%, #f9d4ff 64%);
}
::-webkit-scrollbar-track {
	background: #ffffff;
	border-radius: 10px;
	box-shadow: inset 7px 10px 12px 0px #f0f0f0;
}
/* END CUSTOM SCROLLBAR */
